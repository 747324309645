import http from 'config/Http';

export const getLectures = function(topicId:string, onSuccess:Function, onFailure:Function) {
  http.get(`/course/1/lectures/${topicId}/`)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch(() => {
      onFailure();
    });
}

export const markRead = function(topicId:string, onSuccess:Function, onFailure:Function) {
  http.post(`/course/1/lectures/${topicId}/read/`)
    .then(() => {
      onSuccess();
    })
    .catch(error => {
      onFailure();
    });
}