import {
  TopicsList,
  Topic,
  SubTopics,
} from 'chapters/Chapters.css';
import { useEffect, useRef, useState } from 'react';
import Nav from 'utils/Nav';
import { Button } from 'utils/Buttons';
import { Link } from "react-router-dom";
import { getChapters } from './Chapters.svc';
import { Chapter, Topic as ModelTopic } from './Chapters.models';
import Loading from 'utils/Loading';
import i18n from 'utils/LabelLang';
import Error from 'utils/Error';

function _i18n(label:string) {
  return i18n("chapters", label);
}

type SubtopicProps = {
  topic: ModelTopic
}

function SubTopic({topic}:SubtopicProps) {
  return (
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div className='buttons'>
        <Button className={topic.completed?.theory ? 'completed' : ''} color="black" as={Link} to={`/theory/${topic.id}`}>{_i18n("theory")}</Button>
        <Button className={topic.completed?.practice ? 'completed' : ''} color="black" as={Link} to={`/practice/${topic.id}`}>{_i18n("practice")}</Button>
      </div>
      <div className="ms-2 me-auto li-numbered">
        <div className="fw-bold">{topic.name}</div>
        {topic.description}
      </div>
    </li>
  );
}

type ListSubtopicsProps = {
  topics: ModelTopic[],
}

function ListSubtopics({topics}:ListSubtopicsProps) {

  return (
    <SubTopics>
      <ol className="list-group">
        {topics.map(topic => <SubTopic key={topic.id} topic={topic} />)}
      </ol>
    </SubTopics>
  );
}

function Chapters() {
  const currentActive = localStorage.getItem('active') !== null ? localStorage.getItem('active') : "-1";
  const [active, setActive] = useState(parseInt(currentActive + ''));
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const ref = useRef(document.createElement("div"));
  const [showError, setShowError] = useState(false);

  function onSuccess(listChapters:Chapter[]) {
    setChapters(listChapters);
    const elementScroll = ref.current.querySelector(`#chapter-${currentActive}`);
    if(elementScroll) {
      elementScroll.scrollIntoView();
    }
  }

  function onError() {
    setShowError(true);
  }

  useEffect(() => {
    if(chapters.length === 0) {
      getChapters(onSuccess, onError);
    }
  }, []);

  function handleSetActive(id:number) {
    setActive(id);
    localStorage.setItem('active', id + '');
  }

  function renderTopics() {
    if (chapters.length === 0) return <Loading />;
    return chapters.map((chapter, index) => {
      const isCompleted = chapter.completed ? 'completed' : '';
      return (
        <div key={chapter.id}>
          <Topic disabled={!chapter.active}
                 id={`chapter-${chapter.id}`}
                 onClick={() => handleSetActive(chapter.id)}
                 className={chapter.id === active ? 'active' : isCompleted}>
            <h3 className='topic-head'>{_i18n("chapter")} {index + 1}: {chapter.title}</h3>
            <p className='topic-description'>{chapter.description}</p>
          </Topic>
          {chapter.id === active ? <ListSubtopics topics={chapter.topics} key={`sub-${chapter.id}`} /> : null}
        </div>
      );
    });
  }
  
  return (
    <TopicsList>
      <header className='topics-head'>
        <Nav color="#ffffff" />
        <h2>{_i18n("course")}</h2>
      </header>
      <div className='TopicsItems'>
        <div className='TopicsItems' ref={ref}>
          {showError ? <Error /> : renderTopics()}
        </div>
      </div>
    </TopicsList>
  );
}

export default Chapters;