import styled from "styled-components";

export const Lab = styled.div`
  display: flex;
  height: 100%;
  background: #1d2333;
  color: white;
  align-items: stretch;
  font-size: 1.15em;
  flex: 1;

  .Lab-instructions {
    flex-basis: 30%;
    padding: 0 20px;
    user-select: none;
    min-width: 400px;

    h3 {
      margin: 0.9em 0;
    }

    code {
      color: #ffd900!important;
    }

    .hljs {
      padding: 5px 8px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .Lab-terminal {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: url('/term-background.png');
    background-size: cover;
    padding: 20px;
    justify-content: space-between
  }
`;

export const TerminalFooter = styled.div`
  text-align: right;

  & p {
    margin-bottom: 0;
    font-size: 0.8em;
  }
`;

export const Desktop = styled.div`
  .Application {
    text-align: center;
    width: 930px;
    margin: 0 auto;
    background-color: black;
    padding: 15px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
  }

  .outer {
    background: #b4b4b4;
    opacity: 0.9;
    width: 930px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 7px 7px 0px 0px;
    
    &.dark {
      background: #273138;
      
      .dot {
        background: #4a5c66;
      }
    }

    &.yosemite {
      background: #f6f6f6;
      background: -moz-linear-gradient(top,  #f6f6f6 0%, #dadada 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f6f6f6), color-stop(100%,#dadada));
      background: -webkit-linear-gradient(top,  #f6f6f6 0%,#dadada 100%);
      background: -o-linear-gradient(top,  #f6f6f6 0%,#dadada 100%);
      background: -ms-linear-gradient(top,  #f6f6f6 0%,#dadada 100%);
      background: linear-gradient(to bottom,  #f6f6f6 0%,#dadada 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#dadada',GradientType=0 );
      border: 1px solid #d8d8d8;
      border-bottom: 1px solid #b4b4b4;
    }
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #f9f9f9;
    border-radius: 50%;
    margin: 0 4px 0 0;
    
    &.red {
      background: #FF6057;
      border: 1px solid #E14640;
    }

    &.amber {
      background: #FFBD2E;
      border: 1px solid #DFA123;
    }

    &.green {
      background: #27C93F;
      border: 1px solid #1DAD2B;
    }
  }
`;