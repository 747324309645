import i18n from 'utils/LabelLang';

function _i18n(label:string) {
  return i18n("modal", label);
}

function Modal({ children, title, toggleModal }:{
  children: React.ReactNode,
  title:string,
  toggleModal:Function
}) {
  return (
    <>
      <div className="modal fade show" tabIndex={-1} style={{display: "block", color: 'black'}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={() => toggleModal()} />
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => toggleModal()}>{_i18n("close")}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
}

export default Modal;