import http from 'config/Http';

export type LoginPayload = {
  email: string,
  password: string,
}

export type RegisterPayload = {
  username: string,
  email: string,
  password: string,
}

export type LoginError = {
  type: string,
  message: string[],
}

export type SocialAuthPayload = {
  auth_token: string,
}

type PayloadResetPassword = {
  uidb64: string | undefined,
  token: string | undefined,
  password: string,
  confirmPassword: string,
}

export const login = function(payload:LoginPayload, onSuccess:Function, onFailure: Function) {
  http.post('/auth/login/', payload)
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status === 401) {
        if(error.response.data.detail) {
          onFailure({
            type: 'bad-credentials',
            message: [error.response.data.detail],
          });
        } else {
          onFailure({
            type: 'bad-credentials',
            message: ['Email or password are wrong'],
          });
        }
      }
      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
};

export const register = function(payload:RegisterPayload, onSuccess:Function, onFailure: Function) {
  const capitalize = function (str:string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  http.post('/auth/register/', payload)
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status === 400) {
        const errors = [] as string[];
        if(error.response.data.errors) {
          ['username', 'email', 'password'].forEach(function (key) {
            if(typeof error.response.data.errors[key.toLowerCase()] !== 'undefined') {
              const errorMsg = error.response.data.errors[key].join('. ');
              errors.push(`${capitalize(key)}: ${capitalize(errorMsg)}`);
            }
          });
        }
        onFailure({
          type: 'bad-payload',
          message: errors,
        });
      }
      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
};

export const loginSocialAuth = function (socialNetwork:string, payload:SocialAuthPayload, onSuccess:Function, onFailure: Function) {
  http.post(`/social_auth/${socialNetwork}/`, payload)
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status === 401) {
        let errorMessage = '';
        if(error.response.data.detail) {
          errorMessage = error.response.data.detail;
        }
        onFailure({
          type: 'bad-payload',
          message: [errorMessage],
        });
        return;
      }
      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      } else {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
}

export const resendEmail = function (email:string, onSuccess:Function, onFailure:Function) {
  http.post('/auth/resend-email-verify/', {email})
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status === 401) {
        let errorMessage = '';
        if(error.response.data.detail) {
          errorMessage = error.response.data.detail;
        }
        onFailure({
          type: 'bad-payload',
          message: [errorMessage],
        });
      }
      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
}

export const verifyEmail = function (token:string, onSuccess:Function, onFailure:Function) {
  http.get(`/auth/email-verify/?token=${token}`)
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status >= 400) {
        let errorMessage = [];
        if(error.response.data.error) {
          errorMessage.push(error.response.data.error);
        }
        if(error.response.data.password) {
          errorMessage = error.response.data.password;
        }
        onFailure({
          type: 'bad-payload',
          message: errorMessage,
        });
      }
      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
}

export const requestResetPassword = function (email:string, onSuccess:Function, onFailure:Function) {
  http.post('/auth/request-reset-email/', { email })
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status === 401) {
        let errorMessage = '';
        if(error.response.data.detail) {
          errorMessage = error.response.data.detail;
        }
        onFailure({
          type: 'bad-payload',
          message: [errorMessage],
        });
      }
      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
}

export const resetPassword = function (payload:PayloadResetPassword, onSuccess:Function, onFailure:Function) {
  if(payload.password !== payload.confirmPassword) {
    onFailure({type: 'error-password', message: 'The passwords do not match'});
    return;
  }

  http.put(`/auth/password-reset/${payload.uidb64}/${payload.token}/`, { password:payload.password })
    .then(function () {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status >= 400 ) {
        let errorMessage = [];
        if(error.response.data.error) {
          errorMessage.push(error.response.data.error);
        }
        if(error.response.data.password) {
          errorMessage = error.response.data.password;
        }
        onFailure({
          type: 'bad-payload',
          message: errorMessage,
        });
      }

      if(!error.response && error.request) {
        onFailure({
          type: 'error-server',
          message: ['There is an error with the server. Try again later'],
        });
      }
    });
}

export const deleteAccount = function(token:string, onSuccess:Function, onFailure:Function) {
  http.get(`/auth/confirm-delete-account/?token=${token}`)
    .then(() => {
      onSuccess();
    })
    .catch(function (error) {
      if (error.response && error.response.status >= 400) {
        let errorMessage = '';
        if(error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        onFailure(errorMessage);
      }
      if(!error.response && error.request) {
        onFailure('There is an error with the server. Try again later');
      }
    });
}
