import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Term from 'practice/Term';
import { Button } from 'utils/Buttons';
import Nav from "utils/Nav";
import { Lab, TerminalFooter } from './Practice.css';
import { Practice } from 'practice/Practice.models';
import { getPractice, markPracticed } from "practice/Practice.svc";
import { converter } from "utils/converter";
import { trigger } from 'practice/events';
import Loading from "utils/Loading";
import Error from "utils/Error";
import i18n from 'utils/LabelLang';


function _i18n(label:string) {
  return i18n("practice", label);
}

function practiceReducer(state:any, action:any) {
  switch (action.type) {
    case 'setPractices':
      return {
        ...state,
        practices: action.payload
      };
    case 'nextPractice':
      let next = state.index + 1;
      if(next >= state.practices.length) {
        next = state.practices.length - 1;
      }
      return {
        ...state,
        index: next,
      };
    case 'previousPractice':
      let previous = state.index - 1;
      if(previous <= 0) {
        previous = 0;
      }
      return {
        ...state,
        index: previous,
      };
    case 'error':
      return {
        ...state,
        error: true,
      }
    default:
      return state;
  }
}

type DispatchParameter = {
  payload: any,
}

function useReducer(reducer:any, initialState:any) {
  const [state, setState] = useState(initialState);

  function dispatch(action:DispatchParameter) {
    const nextState = reducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

function Content() {
  let { topicId } = useParams();
  let navigate = useNavigate();

  const [state, dispatch] = useReducer(practiceReducer, {
    practices: [] as Practice[],
    index: 0,
    error: false,
  });

  function onSuccess(practices:Practice[]) {
    dispatch({type: 'setPractices', payload: practices});
  }

  function onError() {
    dispatch({type: 'error'});
  }

  function handleOnClick(event:React.MouseEvent<HTMLElement>) {
    const target = event.target as HTMLElement;
    if(target.className) {
      const text = target.closest('.hljs') !== null
        ? (target.closest('.hljs') as HTMLElement).innerText : '';
      trigger('xterm', {msg: text});
    }
  }

  function buildContainer() {
    if (state.error) return <Error />
    if (state.practices.length <= 0) return <Loading />;
    const practice = state.practices[state.index];
    return (
      <div className='container'>
        <h3>{practice.topic} - <small>Part {state.index + 1}</small></h3>
        <hr></hr>
        <div onClick={handleOnClick} dangerouslySetInnerHTML={{__html: converter(practice.content).innerHTML}}></div>
      </div>
    );
  }

  function GroupButton({onClick, label}:{onClick:Function, label:string}) {
    return (
      <div className="btn-group me-2">
        <Button type="button" onClick={() => onClick()}>{label}</Button>
      </div>
    )
  }

  function onSuccessSave() {
    window.location.href = "/chapters";
  }

  function finishPractice() {
    markPracticed(topicId as string, onSuccessSave, onError);
  }

  function buttons() {
    return (
      <>
        {state.index > 0 ?
          <GroupButton onClick={() => dispatch({type: 'previousPractice'})} label={_i18n("previous")} /> : null}

        {state.index < (state.practices.length - 1) ?
          <GroupButton onClick={() => dispatch({type: 'nextPractice'})} label={_i18n("next")} /> : null}

        {state.index >= (state.practices.length - 1) ?
          <GroupButton onClick={finishPractice} label={_i18n("finish")} /> : null}
      </>
    );
  }

  useEffect(() => {
    const id = topicId !== undefined ? topicId : '';
    if(state.practices.length === 0) {
      getPractice(id, onSuccess, onError);
    }
  }, [topicId]);

  return (
    <Lab>
      <div className='Lab-instructions'>
        <Nav subnav={[{ name: _i18n("goBack"), to: '/chapters' }]} color="#ffffff" />
        {buildContainer()}
        <div className="container">
          <div className="btn-toolbar">
            {state.practices.length > 0 ? buttons() : null}
          </div>
        </div>
        <TerminalFooter>
          <p>TlaLoc.</p>
        </TerminalFooter>
      </div>
      <div className='Lab-terminal'>
        <Term />
      </div>
    </Lab>
  );
}

export default Content;