const i18n = {
  en: {
    login: {
      welcome: "Welcome to",
      slogan: "Learn Linux faster",
      subslogan: "Complete real scenarios",
      btnLogin: "Login",
      btnRegister: "Register",
      loginHead: "Account Login",
      loginEmail: "Email address",
      loginPassword: "Your password",
      loginForget: "Forgot password",
      loginAction: "Login to your account",
      loginSocial: "Login with your social account",
      signupHead: "Create your account",
      signupUser: "Username",
      signupEmail: "Email address",
      signupPassword: "Your password",
      signpAction: "Create your account",
      signupSocial: "Signup with your social account",
      newPasswordLegend: "Set your new password",
      newPasswordPlaceholder: "Your new password",
      confirmPasswordPlaceholder: "Confirm your new password",
      savePassword: "Save new password",
      successNewPassword: "Your password has been updated",
      successNewPasswordLegend: "Now try to login with your new password.",
      successGoBack: "Go back",
      resendEmailLabel: "Email address",
      resendEmailBack: "Go back to login",
      resendEmailSuccess: "Email has been sent",
      resendEmailHead: "Resend email",
      resendEmailAction: "Resend email to verify account",
      resendEmailInstructions: "Please check your inbox and follow the instructions to verify your account.",
      resetRequestLegend: "Request reset password",
      resetRequestAction: "Request reset password",
      resetRequestSuccess: "Please check your inbox and follow the instructions to reset your password.",
    },
    chapters: {
      theory: "Theory",
      practice: "Practice",
      chapter: "Chapter",
      course: "Linux Course",
    },
    modal: {
      close: "Close",
    },
    nav: {
      logout: "Logout",
      profile: "Profile",
    },
    profile: {
      profile: "Profile",
      changePwd: "Change my password",
      advanced: "Advanced",
      userLabel: "Username",
      userPlaceholder: "Your username",
      emailPlaceholder: "Your email",
      emailLabel: "Email",
      connected: "Connected to",
      update: "Update",
      userUpdated: "The username has been updated",
      passwordUpdated: "Your password has been changed successfully!",
      passwordNotEqual: "Passwords are not equal.",
      passwordPlaceholder: "Your current password",
      passwordLabel: "Current password",
      newPasswordPlaceholder: "Your new password",
      newPasswordLabel: "New password",
      confirmPasswordPlaceholder: "Confirm your new password",
      confirmPasswordLabel: "Confirm new password",
      changePassword: "Change password",
      successDeletion: "You have received an email with a link to confirm the deletion of the account.",
      removeAccount: "Remove account",
      removeAccountLegend: "Deleting your account means that all the information like email, username and booked progress will be removed in our servers forever and cannot be recovered.",
      removeAccountAction: "Delete my account",
    },
    theory: {
      goBack: "Go back",
      goOut: "Go out",
      markRead: "Mark as read",
      goToLab: "Go to laboratory",
    },
    practice: {
      previous: "Previous",
      next: "Next",
      finish: "Finish practice",
      goBack: "Go back",
    }
  },
  es: {
    login: {
      welcome: "Bienvenido a",
      slogan: "Aprende Linux fácil",
      subslogan: "Practica con escenarios reales",
      btnLogin: "Acceder",
      btnRegister: "Crear cuenta",
      loginHead: "Acceder a cuenta",
      loginEmail: "Correo electrónico",
      loginPassword: "Tu contraseña",
      loginForget: "Contraseña perdida",
      loginAction: "Accede a tu cuenta",
      loginSocial: "Accede con otras cuentas",
      signupHead: "Crea una cuenta",
      signupUser: "Nombre de usuario",
      signupEmail: "Correo electrónico",
      signupPassword: "Tu contraseña",
      signpAction: "Crea una cuenta",
      signupSocial: "Accede con otras cuentas",
      newPasswordLegend: "Crear una contraseña nueva",
      newPasswordPlaceholder: "Tu nueva contraseña",
      confirmPasswordPlaceholder: "Confirma tu nueva contraseña",
      savePassword: "Guardar contraseña",
      successNewPassword: "Tu contraseña ha sido actualizada",
      successNewPasswordLegend: "Ahora trata de iniciar sesión nuevamente.",
      successGoBack: "Regresar",
      resendEmailLabel: "Correo electrónico",
      resendEmailBack: "Regresar",
      resendEmailSuccess: "El correo electrónico ha sido enviado",
      resendEmailHead: "Reenviar correo electrónico",
      resendEmailAction: "Reenviar correo electrónico",
      resendEmailInstructions: "Por favor checa tu bandeja de correo y sigue las instrucciones para verificar tu cuenta.",
      resetRequestLegend: "Recuperar contraseña",
      resetRequestAction: "Recuperar contraseña",
      resetRequestSuccess: "Por favor checa tu bandeja de entrada y sigue las instrucciones para recuperar tu contraseña.",
    },
    chapters: {
      theory: "Teoría",
      practice: "Práctica",
      chapter: "Capítulo",
      course: "Curso de Linux",
    },
    modal: {
      close: "Cerrar",
    },
    nav: {
      logout: "Salir",
      profile: "Perfil",
    },
    profile: {
      profile: "Perfil",
      changePwd: "Cambiar mi contraseña",
      advanced: "Avanzado",
      userLabel: "Nombre de usuario",
      userPlaceholder: "Tu nombre de usuario",
      emailPlaceholder: "Tu correo electrónico",
      emailLabel: "Correo electrónico",
      connected: "Conectado a",
      update: "Actualizar",
      userUpdated: "El nombre de usuario ha sido actualizado",
      passwordUpdated: "¡Tu contraseña ha sido actualizada!",
      passwordNotEqual: "Las contraseñas no coinciden",
      passwordPlaceholder: "Tu contraseña actual",
      passwordLabel: "Contraseña actual",
      newPasswordPlaceholder: "Tu contraseña nueva",
      newPasswordLabel: "Nueva contraseña",
      confirmPasswordPlaceholder: "Confirma tu contraseña nueva",
      confirmPasswordLabel: "Confirmar contraseña nueva",
      changePassword: "Cambiar contraseña",
      successDeletion: "Has recibido un email con un enlace para confirmar que tu cuenta será eliminada.",
      removeAccount: "Eliminar cuenta",
      removeAccountLegend: "Eliminar tu cuenta significa que toda la información como correo electrónico, nombre de usuario y progreso del curso será borrado en nuestros servidores por siempre y no podrá ser recuperado.",
      removeAccountAction: "Borrar mi cuenta",
    },
    theory: {
      goBack: "Regresar",
      goOut: "Salir",
      markRead: "Marcar como leído",
      goToLab: "Ir a laboratorio",
    },
    practice: {
      previous: "Anterior",
      next: "Siguiente",
      finish: "Finalizar práctica",
      goBack: "Regresar",
    }
  },
}

export default i18n;