import http from 'config/Http';

export const getMe = function (onSucces:Function, onError:Function) {
  http.get('/auth/me/')
    .then(response => {
      onSucces(response.data);
    })
    .catch(error => {
      //handle error
    });
}

export const changePassword = function (payload:{password:string, new_password:string}, onSucces:Function, onError:Function) {
  http.patch('/auth/change-password/', payload)
    .then(response => {
      onSucces(response.data);
    })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        let errorMessage = '';
        if(error.response.data.non_field_errors) {
          errorMessage = error.response.data.non_field_errors.join(' ');
        }
        onError(errorMessage);
      }
      if(!error.response && error.request) {
        onError('There is an error with the server. Try again later');
      }
    });
}

export const changeUsername = function (username:string, onSucces:Function, onError:Function) {
  http.patch('/auth/change-profile-name/', {username})
    .then(() => {
      onSucces();
    })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        let errorMessage = '';
        if(error.response.data.non_field_errors) {
          errorMessage = error.response.data.non_field_errors.join(' ');
        }
        onError(errorMessage);
      }
      if(!error.response && error.request) {
        onError('There is an error with the server. Try again later');
      }
    });
}

export const removeUser = function(onSuccess:Function, onError:Function) {
  http.delete('/auth/delete-account/')
    .then(() => {
      onSuccess();
    })
    .catch(error => {
      let errorMessage = '';
      if (error.response && error.response.status === 400) {
        if(error.response.data.non_field_errors) {
          errorMessage = error.response.data.non_field_errors.join(' ');
        }
      } else if(!error.response && error.request) {
        errorMessage = 'There is an error with the server. Try again later';
      } else {
        errorMessage = "There is an error with the server. Try again later";
      }
      onError(errorMessage);
    });
}