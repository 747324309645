import { AuthForm } from "login/Login.css";
import { useParams } from "react-router-dom";
import { resetPassword } from "login/Login.svc";
import { useState } from "react";
import { Link } from "react-router-dom";
import i18n from 'utils/LabelLang';


function _i18n(label:string) {
  return i18n("login", label);
}

function reducer(state:any, action:any) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'changeView':
      return {
        ...state,
        isLoading: false,
        currentView: action.payload,
      };
    case 'error':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

function useReducer(reducer:any, initialState:any) {
  const [state, setState] = useState(initialState);

  function dispatch(action:{payload: any, type:string}) {
    const nextState = reducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

function ResetPassword() {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    error: '',
    currentView: 'form',
  });

  let { uidb64, token } = useParams();

  function onSuccess() {
    dispatch({type: 'changeView', payload: 'success'});
  }

  function onError(error:any) {
    dispatch({type: 'error', payload:error.message});
  }

  function handleOnSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({type: 'loading', payload: true});

    resetPassword({
      uidb64: uidb64,
      token: token,
      password: event.currentTarget.password.value,
      confirmPassword: event.currentTarget['new-password-confirm'].value,
    }, onSuccess, onError);
  }

  function buildContent(viewName:string) {
    switch(viewName) {
      case 'form':
        return(
          <>
            <legend>{_i18n("newPasswordLegend")}</legend>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                name="new-password"
                id="password"
                placeholder={_i18n("newPasswordPlaceholder")}
                autoComplete="off"
                disabled={state.isLoading}
                required />
              <label htmlFor="password">{_i18n("newPasswordPlaceholder")}</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                name="new-password-confirm"
                id="password-confirm"
                autoComplete="off"
                placeholder={_i18n("confirmPasswordPlaceholder")}
                disabled={state.isLoading}
                required />
              <label htmlFor="password-confirm">{_i18n("confirmPasswordPlaceholder")}</label>
            </div>
            {state.error ? <p className="text-danger error-msg">{state.error}</p> : null}
            <br />
            <div className="d-grid gap-2">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={state.isLoading}>{_i18n("savePassword")}</button>
            </div>
          </>
        );
      case 'success':
        return (
          <>
            <legend>{_i18n("successNewPassword")}</legend>
            <p>{_i18n("successNewPasswordLegend")}</p>
            <div className="d-grid gap-2">
              <Link to="/login" className="btn btn-primary">{_i18n("successGoBack")}</Link>
            </div>
          </>
        );
    }
  }
  
  return (
    <AuthForm className="row align-items-center justify-content-center">
      <form onSubmit={handleOnSubmit}>
        {buildContent(state.currentView)}
      </form>
    </AuthForm>
  );
}

export default ResetPassword;