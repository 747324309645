import styled from 'styled-components';

export const SubTopics = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin: -20px auto 20px auto;

  & .buttons button,
  & .buttons a {
    margin: 0 2px;
  }

  & .li-numbered {
    padding-left: 15px;
    position: relative;
  }

  & .li-numbered:before {
    content: counters(section, ".") ". ";
    counter-increment: section;
    position: absolute;
    left: 0;
  }

  ol {
    counter-reset: section;
  }
`;

export const TopicsList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  background-size: cover;
  width: 100%;

  & .TopicsItems {
    flex: 1;
    overflow-y: auto;
  }

  header.topics-head {
    text-align: center;
    padding-bottom: 1em;
    background: #1d2333;
    color: white;
    max-width: 800px;
    width: 100%;
    margin: 10px auto 20px;
    border-radius: 4px;
  }
`;

export const Topic = styled.button`
  max-width: 800px;
  width: 100%;
  display: block;
  background: none;
  border: none;
  text-align: left;
  margin: 0 auto 20px auto;
  background-color: #f2f8f9;
  color: #666666;
  border-radius: 4px;
  padding: 3em;
  position: relative;
  overflow: hidden;

  &.completed {
    background: repeating-linear-gradient( 45deg, #f4eaff, #f4eaff 10px, #faf6ff 10px, #faf6ff 20px );
  }

  &:after {
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #1d2333;
    border-radius: 0 4px 0 32px;
    content: '→';
    color: white;
    line-height: 28px;
    text-align: right;
    padding-right: 7px;
    z-index: 1;
    transition: transform 0.2s ease-out;
  }

  &:hover {

    p {
      color: black;
    }
  }

  &.active {
    &:after {
      transform: scale(79);
      color: #00838d;
    }

    p, h3.topic-head {
      color: #ffffff;
      transition: all 0.3s ease-out;
    }
  }

  &:disabled {
    filter: grayscale(100%);
    cursor: default;
    border: 1px solid transparent;

    &:after {
      background: #afafaf;
    }

    p, h3 {
      color: #afafaf;
    }
  }

  h3.topic-head {
    display: inline;
    margin-right: 0.15em;
    color: #262626;
    z-index: 2;
    position: relative;
  }

  p.topic-description {
    display: inline;
    z-index: 2;
    position: relative;
  }
`;
