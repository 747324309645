import { Reader } from "theory/Theory.css";
import { Button } from "utils/Buttons";
import Nav from "utils/Nav";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLectures, markRead } from "theory/Therory.svc";
import { Lecture } from "theory/Theory.models";
import { converter } from "utils/converter";
import Loading from "utils/Loading";
import i18n from 'utils/LabelLang';
import Error from 'utils/Error';


function _i18n(label:string) {
  return i18n("theory", label);
}

function Theory() {
  let { topicId } = useParams();
  const [lecture, setLecture] = useState<Lecture>({} as Lecture);
  const [contents, setContents] = useState<HTMLDivElement>(document.createElement('div'));
  const [showError, setError] = useState(false);

  function onSuccess(lecture:Lecture) {
    setLecture(lecture);
    setContents(converter(lecture.content));
  }

  function onError() {
    setError(true);
  }

  function onSuccessRead() {

  }

  function markAsRead() {
    markRead(topicId as string, onSuccessRead, onError);
  }

  useEffect(() => {
    const id = topicId !== undefined ? topicId : '0';
    getLectures(id, onSuccess, onError);
  }, [topicId]);

  function renderContent() {
    return contents.innerHTML === '' ? <Loading /> : <div className="content" dangerouslySetInnerHTML={{__html: contents.innerHTML}} />;
  }

  return (
    <Reader>
      <div className="reader-header">
        <Nav subnav={[{ name: _i18n("goBack"), to: '/chapters' }]} />
      </div>
      <div className="reader-content">
        <h1>{lecture.topic}</h1>
        <hr />
        <figure>
          <blockquote className="blockquote">
            <p>{lecture.quote}</p>
          </blockquote>
          <figcaption className="blockquote-footer">
            {lecture.quote_sub} <cite title={lecture.quote_source}>{lecture.quote_source}</cite>
          </figcaption>
        </figure>
        {showError ? <Error negative={true} /> : renderContent()}
        <div className="buttons">
          <Button as={Link} to="/chapters">{_i18n("goOut")}</Button>
          <Button onClick={markAsRead}>{_i18n("markRead")}</Button>
          <Button as={Link} to={`/practice/${topicId}`}>{_i18n("goToLab")}</Button>
        </div>
      </div>
    </Reader>
  );
}

export default Theory;