import React, { useState, MouseEvent } from "react";
import { Intro, AuthForm } from "login/Login.css";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import {
  LoginError,
  login,
  register,
  loginSocialAuth,
} from "login/Login.svc";
import { Link, useNavigate } from "react-router-dom";
import config from 'config/config';
import i18n from 'utils/LabelLang';

function _i18n(label:string) {
  return i18n("login", label);
}

interface Props {
  onSubmit: React.FormEventHandler<HTMLFormElement>,
  validatedForm: boolean,
  isLoading: boolean,
  onGoogleAuth: React.MouseEventHandler<HTMLButtonElement> ,
  onFacebookAuth: Function,
  showError: boolean,
  errors: string[],
}

interface FBButtonProps {
  onFacebookAuth:Function,
  isLoading: boolean,
}

interface GoogleButtonProps {
  onGoogleAuth:Function,
  isLoading: boolean,
}


function FBButton ({onFacebookAuth, isLoading}:FBButtonProps) {
  return <FacebookLogin
    appId={config.FACEBOOK_APP_ID}
    isDisabled={isLoading}
    autoLoad={false}
    fields="email,username"
    callback={(userinfo) => onFacebookAuth(userinfo)}
    cssClass="btn btn-fb me-md-2"
    textButton="Facebook"
    icon={<svg aria-hidden="true" className="facebook-login" width="18" height="18" viewBox="0 0 18 18"><path d="M3 1a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Zm6.55 16v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h-2.5Z" fill="#4167B2"></path></svg>}
    />
}

function GoogleButton({onGoogleAuth, isLoading}:GoogleButtonProps) {
  return (
    <GoogleLogin
      clientId={config.GOOGLE_CLIENT_ID}
      buttonText="Google"
      render={({onClick, disabled}:{
        onClick: () => void;
        disabled?: boolean | undefined;
      }) => {
        return <button className="btn btn-gg" type="button" disabled={disabled} onClick={onClick}>
          <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z" fill="#4285F4"></path><path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z" fill="#34A853"></path><path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z" fill="#FBBC05"></path><path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z" fill="#EA4335"></path></svg>
          <span>Google</span>
        </button>
      }}
      disabled={isLoading}
      onSuccess={(success) => onGoogleAuth(success)}
      onFailure={(error) => console.log(error)}
      cookiePolicy="single_host_origin"
      isSignedIn={false}
      autoLoad={false}
      />
  )
}

function RegisterForm ({
  onSubmit,
  validatedForm,
  isLoading,
  onGoogleAuth,
  onFacebookAuth,
  showError,
  errors,
}:Props) {
  return (
    <form key="register" onSubmit={onSubmit} className={validatedForm ? 'was-validated' : ''}>
      <legend>{_i18n("signupHead")}</legend>
      <div className="form-floating mb-3">
        <input type="text"
                className="form-control"
                name="registerUsername"
                id="registerUsername"
                placeholder="Your username"
                autoComplete="off"
                disabled={isLoading}
                required />
        <label htmlFor="registerUsername">{_i18n("signupUser")}</label>
      </div>
      <div className="form-floating mb-3">
        <input type="email"
                className="form-control"
                name="registerEmail"
                id="registerEmail"
                placeholder="name@example.com"
                disabled={isLoading}
                autoComplete="off"
                required />
        <label htmlFor="registerEmail">{_i18n("signupEmail")}</label>
      </div>
      <div className="form-floating mb-3">
        <input type="password"
                className="form-control"
                name="registerPassword"
                id="registerPassword"
                autoComplete="off"
                disabled={isLoading}
                placeholder="Password"
                required />
        <label htmlFor="registerPassword">{_i18n("signupPassword")}</label>
      </div>
      <p className="small">Creating an account means that you agree with our <Link target={'_blank'} to={"/pages/privacy-policy"}>Privacy Policy</Link> and our <Link target={'_blank'} to={'/pages/terms-of-service'}>Terms of Service</Link>.</p>
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary" disabled={isLoading}>{_i18n("signpAction")}</button>
      </div>
      { showError ? (
        <div className="error-message-box">
          {errors.map((error, index) => {
            return <p key={index} className="text-danger error-msg">{error}</p>;
          })}
        </div>
      ) : null }
      <p className="lined">{_i18n("signupSocial")}</p>
      <div className="social-account">
        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
          <FBButton onFacebookAuth={onFacebookAuth} isLoading={isLoading} />
          <GoogleButton onGoogleAuth={onGoogleAuth} isLoading={isLoading} />
        </div>
      </div>
    </form>
  );
}

function LoginForm({
  onSubmit,
  validatedForm,
  isLoading,
  onGoogleAuth,
  onFacebookAuth,
  showError,
  errors,
}:Props) {
  return (
    <form key="login" onSubmit={onSubmit} className={validatedForm ? 'was-validated' : ''}>
      <legend>{_i18n("loginHead")}</legend>
      <div className="form-floating mb-3">
        <input type="email"
               className="form-control"
               name="email"
               id="email"
               placeholder="name@example.com"
               disabled={isLoading}
               required />
        <label htmlFor="email">{_i18n("loginEmail")}</label>
      </div>
      <div className="form-floating mb-3">
        <input type="password"
               className="form-control"
               name="password"
               id="password"
               autoComplete="login-password"
               disabled={isLoading}
               placeholder="Password"
               required />
        <label htmlFor="password">{_i18n("loginPassword")}</label>
      </div>
      <div className="form-text text-end">
        <Link to="/reset-password-request">{_i18n("loginForget")}</Link>
      </div>
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary" disabled={isLoading}>{_i18n("loginAction")}</button>
      </div>
      { showError ? (
        <div className="error-message-box">
          {errors.map((error, index) => {
            return <p key={index} className="text-danger error-msg">{error}</p>;
          })}
        </div>
      ) : null }
      <p className="lined">{_i18n("loginSocial")}</p>
      <div className="social-account">
        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
          <FBButton onFacebookAuth={onFacebookAuth} isLoading={isLoading} />
          <GoogleButton onGoogleAuth={onGoogleAuth} isLoading={isLoading} />
        </div>
      </div>
    </form>
  );
}

function loginReducer(state:any, action:any) {
  switch (action.type) {
    case 'activeForm':
      return {
        ...state,
        wasValidated: false,
        showError: false,
        activeForm: action.payload,
      };
    case 'wasValidated':
      return {
        ...state,
        wasValidated: action.payload,
      };
    case 'loading':
      return {
        ...state,
        showError: false,
        isLoading: action.payload,
      };
    case 'showError':
      return {
        ...state,
        showError: true,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}

type DispatchParameter = {
  payload: any,
}

function useReducer(reducer:any, initialState:any) {
  const [state, setState] = useState(initialState);

  function dispatch(action:DispatchParameter) {
    const nextState = reducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

function Login() {
  let navigate = useNavigate();
  const [state, dispatch] = useReducer(loginReducer, {
    activeForm: 'login',
    wasValidated: false,
    isLoading: false,
    showError: false,
    errors: [] as string[],
  });

  function switchForm(event:MouseEvent<HTMLButtonElement>) {
    dispatch({ type: 'activeForm', payload: event.currentTarget.name });
  }

  function onSuccessfulAccess() {
    navigate("/chapters");
  }

  function onErrorAccess(error:LoginError) {
    dispatch({ type: 'showError', payload: error.message });
  }

  function onLoginSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(event.currentTarget.checkValidity()) {
      dispatch({ type: 'loading', payload: true })
      const payload = {
        email: event.currentTarget.email.value,
        password: event.currentTarget.password.value,
      };
      login(payload, onSuccessfulAccess, onErrorAccess);
    } else {
      dispatch({ type: 'wasValidated', payload: true });
    }
  }

  function onRegisterSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(event.currentTarget.checkValidity()) {
      dispatch({ type: 'loading', payload: true });
      const payload = {
        username: event.currentTarget.registerUsername.value,
        email: event.currentTarget.registerEmail.value,
        password: event.currentTarget.registerPassword.value,
      };
      register(payload, onSuccessfulAccess, onErrorAccess);
    } else {
      dispatch({ type: 'wasValidated', payload: true });
    }
  }

  function onGoogleAuth(config:any) {
    console.log(config);
    loginSocialAuth('google', {auth_token: config.tokenId}, onSuccessfulAccess, onErrorAccess);
  }

  function onFacebookAuth(config:any) {
    if(config.status !== "unknown") {
      loginSocialAuth('facebook', {auth_token: config.accessToken}, onSuccessfulAccess, onErrorAccess);
    } else {
      dispatch({ type: 'loading', payload: false });
    }
  }

  return (
    <AuthForm className="row align-items-center justify-content-center">
      <Intro className="col-sm-7 text-center">
        <div className="intro-group">
          <div className="description">
            <h2>{_i18n("welcome")}</h2>
            <h1>Tlaloc.io</h1>
            <p>{_i18n("slogan")}</p>
            <p>{_i18n("subslogan")}</p>
          </div>
          <div className="row" role="group" aria-label="Basic mixed styles example">
            <button type="button"
                    onClick={switchForm}
                    name="login"
                    className="col btn btn-outline-light btn-lg"
                    disabled={state.activeForm === 'login' || state.isLoading}>{_i18n("btnLogin")}</button>
            <button type="button"
                    onClick={switchForm}
                    name="register"
                    className="col btn btn-outline-light btn-lg"
                    disabled={state.activeForm === 'register' || state.isLoading}>{_i18n("btnRegister")}</button>
          </div>
        </div>
      </Intro>
      <div className="col">
        {
          state.activeForm === 'login' ?
          <LoginForm
            onSubmit={onLoginSubmit}
            validatedForm={state.wasValidated}
            isLoading={state.isLoading}
            showError={state.showError}
            errors={state.errors}
            onGoogleAuth={onGoogleAuth}
            onFacebookAuth={onFacebookAuth} /> :
          <RegisterForm
            onSubmit={onRegisterSubmit}
            validatedForm={state.wasValidated}
            isLoading={state.isLoading}
            showError={state.showError}
            errors={state.errors}
            onGoogleAuth={onGoogleAuth}
            onFacebookAuth={onFacebookAuth} />
        }
      </div>
    </AuthForm>
  );
}

export default Login;