import { useReducer, useRef } from "react";
import { changePassword } from "profile/Profile.svc";
import { ProfileWrapper } from "profile/Profile.css";
import i18n from 'utils/LabelLang';

function _i18n(label:string) {
  return i18n("profile", label);
}

const reducer = function (state:any, action: any):any {
  switch (action.type) {
    case 'setProfile':
      return {
        ...state,
        isLoading: false,
        user: action.payload
      }
    case 'setLoading':
      return {
        ...state,
        isLoading: action.payload
      }
    case 'error':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case 'success':
      return {
        ...state,
        success: _i18n("passwordUpdated"),
        isLoading: false,
      }
    default:
      return state;
  }
}

function ChangePassword() {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    user: null,
    error: '',
  });
  const form = useRef(document.createElement('form'));

  const onSuccess = function () {
    dispatch({type: 'success'});
    form.current.reset();
  }

  const onError = function (error:string) {
    dispatch({type: 'error', payload: error});
  }

  const handleOnSubmit = function(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const payload = {
      password: event.currentTarget.currentPassword.value as string,
      new_password: event.currentTarget.newPassword.value as string,
      confirm_password: event.currentTarget.confirmNewPassword.value as string,
    };

    if(payload.new_password !== payload.confirm_password) {
      dispatch({type: 'error', payload: _i18n("passwordNotEqual")});
      return;
    }

    dispatch({type: 'setLoading', payload: true});
    changePassword({
      password: payload.password,
      new_password: payload.new_password,
    }, onSuccess, onError);
  }

  return (
    <ProfileWrapper className="">
      <form onSubmit={handleOnSubmit} ref={form}>
        <div className="form-floating mb-3">
          <input type="password"
                  className="form-control"
                  name="currentPassword"
                  id="currentPassword"
                  placeholder={_i18n("passwordPlaceholder")}
                  autoComplete="none"
                  disabled={state.isLoading}
                  required />
          <label htmlFor="currentPassword">{_i18n("passwordLabel")}</label>
        </div>
        <div className="form-floating mb-3">
          <input type="password"
                  className="form-control"
                  name="newPassword"
                  id="newPassword"
                  placeholder={_i18n("newPasswordPlaceholder")}
                  autoComplete="none"
                  disabled={state.loading}
                  required />
          <label htmlFor="newPassword">{_i18n("newPasswordLabel")}</label>
        </div>
        <div className="form-floating mb-3">
          <input type="password"
                  className="form-control"
                  name="confirmNewPassword"
                  id="confirmNewPassword"
                  autoComplete="none"
                  placeholder={_i18n("confirmPasswordPlaceholder")}
                  disabled={state.loading}
                  required />
          <label htmlFor="confirmNewPassword">{_i18n("confirmPasswordLabel")}</label>
        </div>
        <p className="text-danger text-start">{state.error}</p>
        <p className="text-success text-start">
          <strong>{state.success}</strong>
        </p>
        <div className="d-grid gap-2">
          <button type="submit" className="btn btn-primary" disabled={state.isLoading}>{_i18n("changePassword")}</button>
        </div>
      </form>
    </ProfileWrapper>
  );
}

export default ChangePassword;