import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthForm } from "login/Login.css";
import { verifyEmail, LoginError } from "login/Login.svc";

type DispatchParameter = {
  payload: any,
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyEmailReducer(state:any, action:any) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'updateNode':
      return {
        ...state,
        isLoading: false,
        activeNode: action.payload,
      };
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        activeNode: 'error',
      };
    default:
      return state;
  }
}

function useReducer(reducer:any, initialState:any) {
  const [state, setState] = useState(initialState);

  function dispatch(action:DispatchParameter) {
    const nextState = reducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

function VerifyEmail() {
  const [state, dispatch] = useReducer(VerifyEmailReducer, {
    email: '',
    isLoading: false,
    activeNode: 'form',
    error: '',
  });
  let query = useQuery();

  function onSuccess() {
    dispatch({type: 'updateNode', payload: 'success'});
  }

  function onFailure(error:LoginError) {
    dispatch({type: 'error', payload: error.message[0]});
  }

  useEffect(() => {
    function handleSubmit() {
      const token = query.get("token");
      const safeToken = token ? token : '';

      dispatch({type: 'loading', payload: true});
      if(!state.isLoading && state.activeNode === 'form') {
        verifyEmail(safeToken, onSuccess, onFailure);
      }
    }

    handleSubmit();
  }, [query, state.isLoading, state.activeNode]);

  function buildContent(activeNode:string) {
    switch(activeNode) {
      case 'form':
        return (
          <>
            <legend>Verifying email</legend>
            <p>Your email is being verified. Wait a moment please.</p>
          </>
        );
      case 'success':
        return (
          <>
            <legend>Success!</legend>
            <p>Your email has been verified.</p>
            <div className="d-grid gap-2">
              <Link to="/login" className="btn btn-primary">Go back to login</Link>
            </div>
          </>
        );
      case 'error':
        return (
          <>
            <legend>Error!</legend>
            <p>There was an error trying to verify your email.</p>
            <p className="text-danger"><strong>{state.error}</strong></p>
            {state.error.toLowerCase() === "invalid token" ? (
              <span>
                <Link to="/resend-email">Get another link</Link> to verify your email.
              </span>
            ): null}
          </>
        );
    }
  }

  return (
    <AuthForm className="row align-items-center justify-content-center">
      <form>
        {buildContent(state.activeNode)}
      </form>
    </AuthForm>
  );
}

export default VerifyEmail;