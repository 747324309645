export const keyEventToHex = function(event:any) {
  let str = "";
  const keyCode = event.keyCode;
  switch(keyCode) {
    // backspace
    case 8: str = "\x7f"; break;
    // tab
    case 9: str = "\x09"; break;
    // enter
    case 13: str = "\x0d"; break;
    // escape
    case 27: str = "\x1b"; break;
    // space
    case 57: str = "\x20"; break;
    // left
    case 37:  str = "\x1b[D"; break;
    // right
    case 39: str = "\x1b[C"; break;
    // up
    case 38: str = "\x1b[A"; break;
    // down
    case 40: str = "\x1b[B"; break;
    // delete
    case 46: str = "\x1b[3~"; break;
    // insert
    case 45: str = "\x1b[2~"; break;
    // home
    case 36: str = "\x1b[1~"; break;
    // end
    case 35: str = "\x1b[4~"; break;
    // space
    case 32: str = "\x20"; break;
    // page up
    case 33: str = "\x1b[5~"; break;
    // page down
    case 34: str = "\x1b[6~"; break;
    case 91:
    case 92:
    case 93:
      str= ""; break;
    default:
      // Control Key was pressed in combination with letter
      if (event.ctrlKey) {
        if (event.keyCode >= 65 && event.keyCode <= 90) {
            str = String.fromCharCode(event.keyCode - 64);
        } else if (event.keyCode === 32) {
            str = String.fromCharCode(0);
        }
      } else {
        if(keyCode >= 47) {
          str = event.key;
        }
      }
  }
  return str;
}