import { useReducer, useRef } from "react";
import { removeUser } from "./Profile.svc";
import i18n from 'utils/LabelLang';

function _i18n(label:string) {
  return i18n("profile", label);
}

const reducer = function (state:any, action: any):any {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: '',
      }
    case 'success':
      return {
        ...state,
        isLoading: false,
        success: _i18n("successDeletion"),
        error: '',
      }
    default:
      return state;
  }
}

function AdvancedConfig() {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    error: '',
    success: '',
  });
  const form = useRef(document.createElement('form'));

  const onError = function(error:string) {
    dispatch({type: "error", payload: error});
  }

  const onSuccess = function() {
    dispatch({type: "success"});
  }

  const handleOnSubmit = function(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({type: "setLoading", payload: true});
    removeUser(onSuccess, onError);
  }

  return (
    <form onSubmit={handleOnSubmit} ref={form}>
      <p className="text-start"><strong>{_i18n("removeAccount")}</strong></p>
      <p className="text-start">{_i18n("removeAccountLegend")}</p>
      <p className="text-success text-start">
        <strong>{state.success}</strong>
      </p>
      <p className="text-danger text-start">{state.error}</p>
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-danger" disabled={state.isLoading || state.success !== ''}>{_i18n("removeAccountAction")}</button>
      </div>
    </form>
  );
}

export default AdvancedConfig;