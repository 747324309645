import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { AuthForm } from "login/Login.css";
import { resendEmail, LoginError, requestResetPassword } from "login/Login.svc";
import i18n from 'utils/LabelLang';


function _i18n(label:string) {
  return i18n("login", label);
}

type DispatchParameter = {
  payload: any,
}

function resendEmailReducer(state:any, action:any) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'updateNode':
      return {
        ...state,
        isLoading: false,
        activeNode: action.payload,
      };
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

function useReducer(reducer:any, initialState:any) {
  const [state, setState] = useState(initialState);

  function dispatch(action:DispatchParameter) {
    const nextState = reducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

type ResendEmailProps = {
  legend: string,
  action: string,
  successMsg: string,
}

function ResendEmail({legend, action, successMsg}:ResendEmailProps) {
  const [state, dispatch] = useReducer(resendEmailReducer, {
    email: '',
    isLoading: false,
    activeNode: 'form',
    error: null,
  });
  let location = useLocation();

  function onSuccess() {
    dispatch({type: 'updateNode', payload: 'success'});
  }

  function onFailure(error:LoginError) {
    dispatch({type: 'error', payload: error.message[0]});
  }

  function handleSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({type: 'loading', payload: true});
    switch(location.pathname) {
      case '/resend-email':
        resendEmail(event.currentTarget.email.value, onSuccess, onFailure);
        break;
      case '/reset-password-request':
        requestResetPassword(event.currentTarget.email.value, onSuccess, onFailure);
        break;
    }
  }

  function buildContent(activeNode:string) {
    switch(activeNode) {
      case 'form':
        return (
          <>
            <legend>{legend}</legend>
            <div className="form-floating mb-3">
              <input type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="name@example.com"
                    disabled={state.isLoading}
                    required />
              <label htmlFor="email">{_i18n("resendEmailLabel")}</label>
            </div>
            <p className="text-danger error-msg">{state.error}</p>
            <br />
            <div className="form-text text-end">
              <Link to="/login">{_i18n("resendEmailBack")}</Link>
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary">{action}</button>
            </div>
          </>
        );
      case 'success':
        return (
          <>
            <legend>{_i18n("resendEmailSuccess")}</legend>
            <p>{successMsg}</p>
            <div className="d-grid gap-2">
              <Link to="/login" className="btn btn-primary">{_i18n("resendEmailBack")}</Link>
            </div>
          </>
        );
    }
  }

  return (
    <AuthForm className="row align-items-center justify-content-center">
      <form onSubmit={handleSubmit}>
        {buildContent(state.activeNode)}
      </form>
    </AuthForm>
  );
}

export default ResendEmail;