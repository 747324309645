import { useCallback, useEffect, useRef } from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import {keyEventToHex} from 'utils/xterm';
import createModule from 'libs/riscvemu64-wasm.js';
import { Desktop } from 'practice/Practice.css';
import 'xterm/css/xterm.css';
import { on, off } from 'practice/events';

declare global {
  interface Window { term: any; }
}

window.term = new Terminal({
  fontFamily: 'Consolas, monaco, monospace',
  convertEol: true,
});

const fitAddon = new FitAddon();
window.term.loadAddon(fitAddon);

function Term() {
  let console_queue_char = useRef(null);
  let console_get_size = useRef(null);
  let fs_import_file = useRef(null);
  let display_key_event = useRef(null);
  let display_mouse_event = useRef(null);
  let display_wheel_event = useRef(null);
  let net_write_packet = useRef(null);
  let net_set_carrier = useRef(null);

  const useHookWithRefCallback = function useHookWithRefCallback() {
    const ref = useRef(null)
    const setRef = useCallback(node => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      }
      
      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        window.term.open(node);
        window.term.write("Loading...");
        fitAddon.fit();

        window.term.attachCustomKeyEventHandler((event:any) => {
          if (event.type === "keydown") {
            const str = keyEventToHex(event);
            //console.log(event, str, {ctrlKey: event.ctrlKey});
            if (str) {
              for(let i = 0; i < str.length; i++) {
                // @ts-ignore
                console_queue_char.current(str.charCodeAt(i));
              }
            }
          }
        });        
      }

      ref.current = node
    }, [])
    
    return [setRef]
  }
  const [ref] = useHookWithRefCallback();

  const url = "root-riscv64.cfg";
  const mem_size = 128;
  const cmdline = "";
  const pwd = null;
  const width = 0;
  const height = 0;
  const net_state = 0;
  const drive_url = "";

  useEffect(() => {
    createModule({locateFile: (path:any) => {
      return `/linux/${path}`;
    }}).then((Module:any) => {
        console_queue_char.current = Module.cwrap('console_queue_char', null, ['number']);
        console_get_size.current = Module.cwrap('console_get_size, null', ['number', 'number']);
        fs_import_file.current = Module.cwrap('fs_import_file', null, ['string', 'number', 'number']);
        display_key_event.current = Module.cwrap('display_key_event', null, ['number', 'number']);
        display_mouse_event.current = Module.cwrap('display_mouse_event', null, ['number', 'number', 'number']);
        display_wheel_event.current = Module.cwrap('display_wheel_event', null, ['number']);
        net_write_packet.current = Module.cwrap('net_write_packet', null, ['number', 'number']);
        net_set_carrier.current = Module.cwrap('net_set_carrier', null, ['number']);

        const args = ["string", "number", "string", "string", "number", "number", "number", "string"];
        // @ts-ignore
        const values = [url, mem_size, cmdline, pwd, width, height, (net_state != null) | 0, drive_url];
        Module.ccall("vm_start", null, args, values );
    });
  }, []);

  const typeTerminal = useCallback((event) => {
    const str = event.detail.msg;
    for(let i = 0; i < str.length; i++) {
      // @ts-ignore
      console_queue_char.current(str.charCodeAt(i));
    }
  }, []);


  useEffect(() => {
    on('xterm', typeTerminal)
  }, []);

  return (
    <Desktop>
      <div className="outer yosemite">
        <div className="dot red"></div>
        <div className="dot amber"></div>
        <div className="dot green"></div>
      </div>
      <div className="Application" ref={ref}>
      </div>
    </Desktop>
  );
}

export default Term;
