import './index.css';
import Login from './login/Login';
import ResendEmail from './login/ResendEmail';
import VerifyEmail from './login/VerifyEmail';
import ResetPassword from 'login/ResetPassword';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Practice from 'practice/Practice';
import Chapters from 'chapters/Chapters';
import Theory from 'theory/Theory';
import ConfirmDeleteAccount from 'login/ConfirmDeleteAccount';
import i18n from 'utils/LabelLang';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsService from 'pages/TermsService';
import DeleteAccount from 'pages/DeletePage';


function _i18n(label:string) {
  return i18n("login", label);
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login"
          element={<Login />} />

        <Route path="/resend-email"
          element={
            <ResendEmail
              legend={_i18n("resendEmailHead")}
              action={_i18n("resendEmailAction")}
              successMsg={_i18n("resendEmailInstructions")}/>} />

        <Route path="/verify-email"
          element={<VerifyEmail />} />

        <Route path="/confirm-delete-account"
          element={<ConfirmDeleteAccount />} />

        <Route path="/reset-password-request"
          element={
            <ResendEmail
              legend={_i18n("resetRequestLegend")}
              action={_i18n("resetRequestAction")}
              successMsg={_i18n("resetRequestSuccess")} />} />

        <Route path="/reset-password/:uidb64/:token"
          element={<ResetPassword />} />
        <Route path="/practice/:topicId"
          element={<Practice />} />
        <Route path="/theory/:topicId"
          element={<Theory />} />
        <Route path="/chapters"
          element={<Chapters />} />
        <Route path="/pages/privacy-policy"
          element={<PrivacyPolicy />} />
        <Route path="/pages/terms-of-service"
          element={<TermsService />} />
        <Route path="/pages/delete-account"
          element={<DeleteAccount />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
