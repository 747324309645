import styled from "styled-components";
import { useLocation } from "react-router-dom";

interface ErrorCSSProps {
  readonly invert?: boolean;
}

const ErrorCSS = styled.div<ErrorCSSProps>`
  text-align: center;
  color: white;
  font-size: 16px;
  filter: invert(${props => props.invert ? 1 : 0});

  img {
    max-width: 100px;
    margin-bottom: 20px;
  }

  button.btn.btn-link {
    color: white;
  }
`;

function Error({negative}:{negative?: boolean}) {
  const location = useLocation();

  function handleTry() {
    window.location.href = location.pathname;
  }

  return (
    <ErrorCSS invert={negative}>
      <img src={process.env.PUBLIC_URL + '/error.png'} />
      <p>Error loading content. <button className="btn btn-link" onClick={handleTry}>Try again.</button></p>
    </ErrorCSS>
  );
}

export default Error;