import styled from 'styled-components';

export const Button = styled.button`
  --button-color: ${props => props.color || "#747474"};
  --button-color-hover: black;
  --duration: 3s;
  --stripe-size: 200px;
  --stripe-color: #fbb9b4;
  --stripe-active-color: #ff796f;

  &.completed {
    background:#b3e2b3;
  }

  color: var(--button-color);
  border: 1px solid var(--button-color);
  border-radius: 4px;
  background-size: 100px;
  animation: stripeBackgroundPosition var(--duration) linear infinite;
  background: none;
  text-decoration: none;
  padding: 1px 6px;
  line-height: normal;

  &:hover, &:active {
    color: var(--button-color-hover);
    border: 1px solid var(--button-color-hover);
    font-weight: bold;
    text-shadow: 1px 1px 0px #fff;
    background: repeating-linear-gradient(
      45deg,
      var(--stripe-color),
      var(--stripe-color) 10px,
      #fff5ed 10px,
      #fff5ed 20px
    );
    background-size: 200px;
  }

  @keyframes stripeBackgroundPosition {
    0% {
      background-position: 0 0;
    }
    
    100% {
      background-position: calc(var(--stripe-size) * -1) 0;
    }
  }
`