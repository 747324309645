import http from 'config/Http';

export const getPractice = function(topicId:string, onSuccess:Function, onFailure:Function) {
  http.get(`/course/1/topic/${topicId}/practice/`)
    .then(response => {
      onSuccess(response.data);
    })
    .catch(error => {
      onFailure();
    });
}

export const markPracticed = function(topicId:string, onSuccess:Function, onFailure:Function) {
  http.post(`/course/1/lectures/${topicId}/practice/`)
    .then(() => {
      onSuccess();
    })
    .catch(error => {
      onFailure();
    });
}