import axios from 'axios';
import config from 'config/config';

const http = axios.create({
  baseURL: config.API_URL,
  timeout: 10000,
});

function saveToken({access, refresh}: {access:string, refresh:string}) {
  localStorage.setItem('token', access);
  localStorage.setItem('refresh', refresh);
}

export function cleanToken() {
  localStorage.clear();
}

http.interceptors.request.use(function(axiosConfig) {
  switch(axiosConfig.url){
    case "/social_auth/google/":
    case "/social_auth/facebook/":
    case "/auth/register/":
    case "/auth/login/":
      return axiosConfig;
  }
  if(axiosConfig.baseURL === config.API_URL){
    if(localStorage.getItem('token')) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
    }
  }
  return axiosConfig;
});

http.interceptors.response.use(function (response) {
  if(response.status === 200) {
    switch(response.config.url){
      case "/social_auth/google/":
      case "/social_auth/facebook/":
      case "/auth/login/":
        saveToken(response.data.tokens);
        break;
    }
  }
  return response;
}, function (error) {
  if(error.response.status === 401) {
    switch(error.response.config.url){
      case "/social_auth/google/":
      case "/social_auth/facebook/":
      case "/auth/login/":
        return Promise.reject(error);
    }
    cleanToken();
    window.location.href = '/login';
  }
  return Promise.reject(error);
});

export default http;