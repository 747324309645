import styled from "styled-components";

export const AuthForm = styled.div`
  flex: 1;
  position: relative;
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  padding: 30px 0;
  margin: 0;

  form {
    max-width: 480px;
    padding: 30px;
    background: white;
    border-radius: 10px;

    @media (max-width: 1199.98px) {
      margin: 0 auto;
    }
  }

  .form-text {
    margin-bottom: 20px;
  }

  legend {
    text-align: center;
    margin: 2em 0;
    font-weight: bold;
  }

  .lined {
    margin: 3em 0 2em;
    position: relative;
    text-align: center;

    &:after {
      left: 0;
    }

    &:before {
      right: 0;
    }

    &:after, &:before {
      width: 70px;
      height: 1px;
      background: #d3d3d3;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .facebook-login {
    color: #ffffff;
    path {
      fill: currentColor;
    }
  }

  .btn-fb, .btn-gg {
    min-width: 200px;
    width: 100%;
  }

  .btn-fb {
    background: #3763d2;
    color: #fff;
  }

  .btn-gg {
    background: #ededed;
  }

  .social-account {
    .facebook-login {
      margin-right: 5px;
    }
  }

  .error-msg {
    font-size: 0.9em;
    margin-bottom: 0;
  }

  .error-message-box {
    margin-top: 15px;
    position: relative;
    top: 20px;
  }

`;

export const Intro = styled.div`
  color: white;
  margin-bottom: 30px;

  h1 {
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 0.8em;
  }

  .description {
    margin-bottom: 50px;

    p {
      margin-bottom: 0;
    }
  }

  .intro-group {
    max-width: 480px;
    margin: 0 auto;

    .btn:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
    }

    .btn:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
`;