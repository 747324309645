import { useEffect, useReducer, useRef } from "react";
import Loading from "utils/Loading";
import { UserProfile } from "./Profile.models";
import { getMe, changeUsername } from "./Profile.svc";
import i18n from 'utils/LabelLang';

function _i18n(label:string) {
  return i18n("profile", label);
}

const reducer = function (state:any, action: any):any {
  switch (action.type) {
    case 'setProfile':
      return {
        ...state,
        isLoading: false,
        user: action.payload
      }
    case 'setLoading':
      return {
        ...state,
        isLoading: action.payload
      }
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: '',
      }
    case 'success':
      return {
        ...state,
        isLoading: false,
        success: _i18n("userUpdated"),
        error: '',
      }
    default:
      return state;
  }
}

function userData({diacivatePassword}:{diacivatePassword:Function}) {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    user: null,
    currentView: 'password',
    error: '',
  });
  const form = useRef(document.createElement('form'));

  const onError = function(error:string) {
    dispatch({type: "error", payload: error});
  }

  const onSuccess = function() {
    dispatch({type: "success"});
  }

  useEffect(() => {
    if(state.user === null){
      dispatch({type: "setLoading", payload: true});
      getMe((data:UserProfile) => dispatch({type: "setProfile", payload: data}), onError);
    }
  }, []);

  useEffect(() => {
    if(state.user) {
      form.current.username.value = state.user.username;
      form.current.email.value = state.user.email;
      if(state.user.auth_provider !== 'email') {
        diacivatePassword(true);
      }
    }
  }, [state.user]);

  const handleOnSubmit = function(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({type: "setLoading", payload: true});
    changeUsername(form.current.username.value as string, onSuccess, onError);
  }

  if (state.user === null) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleOnSubmit} ref={form}>
      <div className="form-floating mb-3">
        <input type="text"
                className="form-control"
                name="username"
                id="username"
                placeholder={_i18n("userPlaceholder")}
                disabled={state.isLoading}
                required />
        <label htmlFor="username">{_i18n("userLabel")}</label>
      </div>
      <div className="form-floating mb-3">
        <input type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder={_i18n("emailPlaceholder")}
                disabled={true}
                required />
        <label htmlFor="email">{_i18n("emailLabel")}</label>
      </div>
      <p className="text-start">{state.user && state.user.auth_provider !== 'email' ? `${_i18n("connected")} ${state.user.auth_provider}` : null}</p>
      <p className="text-success text-start">
          <strong>{state.success}</strong>
        </p>
      <p className="text-danger text-start">{state.error}</p>
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary" disabled={state.isLoading}>{_i18n("update")}</button>
      </div>
    </form>
  );
}

export default userData;