import showdown from 'showdown';
import hljs from "highlight.js";

export const converter = function name(content:string):HTMLDivElement {
  var cont = new showdown.Converter();
  const div = document.createElement('div');
  div.innerHTML = cont.makeHtml(content);
  const pres = div.querySelectorAll('pre');
  pres.forEach(elem => {
    const code = elem.children[0].innerHTML;
    const nodeClasses = elem.children[0].className.split(' ');
    elem.className = 'hljs';
    elem.innerHTML = hljs.highlightAuto(code, nodeClasses).value
  });
  return div;
}