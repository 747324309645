function DeleteAccount() {
  return (
    <div className="container">
      <h2>How to delete your account</h2>
      <p>The following instructions show you how to remove your account permamently:</p>
      <ul>
        <li>Login to the application with your credentials</li>
        <li>Click on <strong>Profile</strong> to open the user settings modal</li>
        <li>Click on the <strong>Advanced</strong> tab</li>
        <li>Click on the button <strong>Delete my account</strong></li>
      </ul>
      <p>Once your application is deleted forever, therefore you won't be able to recover your information.</p>
    </div>
  );
}

export default DeleteAccount;