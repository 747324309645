import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'utils/Modal';
import Profile from "profile/Profile";
import { useReducer } from "react";
import { cleanToken } from "config/Http";
import i18n from 'utils/LabelLang';


function _i18n(label:string) {
  return i18n("nav", label);
}

const Navigation = styled.ul`
  font-size: 15px;
`;

type NavProps = {
  subnav?: any[],
  color?: string,
}

function reducer(state:{
  showModal: Boolean
}, action:any):any {
  switch (action.type) {
    case 'toggleModal':
      return {
        ...state,
        showModal: !state.showModal,
      };
    default:
      throw new Error();
  }
}


function Nav({ subnav, color }:NavProps) {
  const style = { color: color || '#d63384' };
  const elements = subnav || [];
  const [state, dispatch] = useReducer(reducer, {showModal: false});
  let navigate = useNavigate();

  function logout() {
    cleanToken();
    navigate('/login');
  }

  return (
  <>
    <Navigation className="nav justify-content-end" color={color}>
      {elements.map((item:any, index:number) => {
        return (
          <li className={`nav-item align-self-start${index===(subnav!.length - 1) ? ' me-auto' : ''}`} key={index}>
            <button className="nav-link btn" onClick={() => window.location.href = item.to } style={style}>{item.name}</button>
          </li>
        );
      })}
      <li className="nav-item">
        <button className="nav-link btn" style={style} onClick={() => dispatch({type: 'toggleModal'})}>{_i18n("profile")}</button>
      </li>
      <li className="nav-item">
        <button className="nav-link btn" style={style} onClick={logout}>{_i18n("logout")}</button>
      </li>
    </Navigation>
    {state.showModal ? (
      <Modal title={_i18n("profile")} toggleModal={() => dispatch({type: 'toggleModal'})}>
        <Profile />
      </Modal>
    ) : null}
  </>
  );
}

export default Nav;