import http from 'config/Http';
import { Chapter, Topic, Completed } from './Chapters.models';

function getTopic(completed:any, topicId:number) {
  return completed.find((item:Completed) => {
    return item.topic === topicId;
  });
}

function processError(error:any) {
  if (error.response && error.response.status >= 400) {
    return {
      type: 'bad-credentials',
      message: ['No credentials'],
    };
  }
  if(!error.response && error.request) {
    return {
      type: 'error-server',
      message: ['There is an error with the server. Try again later'],
    };
  }
}

export const getChapters = function(onSuccess:Function, onFailure: Function) {
    http.get('/course/1/chapters/')
      .then(function (responseChapters) {
        http.get('/course/1/completed/')
          .then(function(responseCompleted) {
            const res = responseChapters.data.map((chapter:Chapter) => {
              let topicCompleted = 0;
              const topics = chapter.topics.map((topic:Topic) => {
                const completed = getTopic(responseCompleted.data, topic.id);
                if(completed !== undefined) {
                  topic.completed = completed;
                  topicCompleted++;
                }
                return topic;
              });
              chapter.topics = topics;
              chapter.completed = chapter.topics.length > 0 && chapter.topics.length === topicCompleted;

              return chapter;
            });
            onSuccess(res);
          })
          .catch(function(error) {
            onFailure(processError(error))
          });
      })
      .catch(function (error) {
        onFailure(processError(error))
      });
  };