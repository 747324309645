import ChangePassword from "./ChangePassword";
import UserData from "./UserData";
import AdvancedConfig from "./AdvancedConfig";
import { ProfileWrapper } from "./Profile.css";
import { useReducer } from "react";
import i18n from 'utils/LabelLang';


function _i18n(label:string) {
  return i18n("profile", label);
}

const reducer = function (state:any, action: any):any {
  switch (action.type) {
    case 'updateView':
      return {
        ...state,
        currentView: action.payload
      }
    case 'diactivatePwd':
      return {
        ...state,
        activePwdButton: false,
      };
    default:
      return state;
  }
}

function Profile() {
  const [state, dispatch] = useReducer(reducer, {
    currentView: 'profile',
    activePwdButton: true,
  });

  function isActive(viewName:string) {
    return state.currentView === viewName ? 'active' : '';
  }

  function diacivatePassword(diactivate:boolean) {
    if(diactivate) {
      dispatch({type: 'diactivatePwd'})
    }
  }

  return (
    <ProfileWrapper className="">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button className={`btn nav-link ${isActive('profile')}`} onClick={() => dispatch({type: 'updateView', payload: 'profile'})}>{_i18n("profile")}</button>
        </li>
        {
          state.activePwdButton ? (
            <li className="nav-item">
              <button className={`btn nav-link ${isActive('password')}`} onClick={() => dispatch({type: 'updateView', payload: 'password'})}>{_i18n("changePwd")}</button>
            </li>
          ) : null
        }
        <li className="nav-item">
          <button className={`btn nav-link ${isActive('advanced')}`} onClick={() => dispatch({type: 'updateView', payload: 'advanced'})}>{_i18n("advanced")}</button>
        </li>
      </ul>
      <br/>
      {state.currentView === 'profile' ? <UserData diacivatePassword={diacivatePassword} /> : null}
      {state.currentView === 'password' ? <ChangePassword /> : null}
      {state.currentView === 'advanced' ? <AdvancedConfig /> : null}
    </ProfileWrapper>
  );
}

export default Profile;