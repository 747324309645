import styled from "styled-components";

export const Reader = styled.div`
  font-size: 1.2em;
  overflow-y: auto;
  height: 100%;

  .reader-content,
  .reader-header {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

  .reader-content {
    margin: 40px auto;
  }

  .buttons {
    margin-top: 40px;

    button, a {
      margin-right: 20px;
    }
  }

  pre {
      //--terminal-color: #282c34;
    --terminal-highlight: #626262;

    //background: var(--terminal-color);
    padding: 10px;
    border-radius: 4px;
    position: relative;
    margin: 25px 0 20px;
    border: 1px solid var(--terminal-highlight);
    //color: white;
    font-family: 'Space Mono', monospace!important;

    & span.enter {
        position: absolute;
        right: 5px;
        top: 50%;
        background: transparent;
        //color: white;
        border: 0;
        transform: translateY(-50%);
    }
  }

  @keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .content {
    animation: fadein 0.5s ease-in alternate;
  }
`;

